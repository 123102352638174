<template>
	<div style="padding-bottom: 80px;">
		<!-- @click-right="toService" -->
		<van-nav-bar class="nav-bar newnavbar" :title="$t('tabbar3')">
			<template #right>
				<van-image width="18" src="/img/clm.png" style="margin-right: 10px;" @click="listType = 'clm'" />
				<van-image width="22" src="/img/row.png" @click="listType = 'row'" />
			</template>
		</van-nav-bar>
		<div class="banner">
			<!-- <swiper class="banner_swiper" :options="bannerSwiperOption">
				<swiper-slide v-for="(v, key) in banners" :key="key">
					<van-image class="banner_img" :src="v.url">
						<template v-slot:loading>
							<van-loading type="spinner" size="20" />
						</template>
					</van-image>
				</swiper-slide>
			</swiper> -->
			<van-swipe :autoplay="3000" :show-indicators="false">
				<van-swipe-item v-for="(v, key) in banners" :key="key">
					<van-image :src="v.url" :height="160" width="100%" fit="cover">
						<template v-slot:loading>
							<van-loading type="spinner" size="20" />
						</template>
						<template v-slot:error><van-image src="/img/error.png" /></template>
					</van-image>
				</van-swipe-item>
			</van-swipe>
		</div>
		<!-- <div class="notice-bar" style="padding: 15px 10px;background: rgb(243, 243, 245);">
			<van-notice-bar class="notice-swipe" background="#ffffff" color="#3e1e88" :text="this.notice">
				<template #left-icon>
					<van-image width="80" src="/img/zxdt2.6fed36ce.png" />
				</template>
			</van-notice-bar>
			<div class="linear-gradient"></div>
		</div> -->
		<!-- <div class="titBox">
			<div>toàn bộ</div>
			<div @click="show = true">bộ lọc vùng</div>
		</div> -->
		<!-- <div class="convention-item">
            <van-tabs animated sticky line-width="100px" :swipeable="true">
                <van-tab title="城市大全">
                    <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div>
                    <div class="address">
                        <van-row v-for="(val, key) in addlist" :key="key" style="margin-bottom: 10px;">
                            <van-col span="6" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
                        </van-row>
                    </div>
                </van-tab>
                <van-tab title="流程价格">
                    <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div>
                    <div class="rig-box">
                        <p class="rig-title">有哪些资源?</p>
                        <p class="rig-content">网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到</p>
                        <p class="rig-title">服务范围?</p>
                        <p class="rig-content">同城免费约炮，任意地点全国空降，全国977+城市速约。</p>
                        <p class="rig-content">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</p>
                    </div>
                </van-tab>
            </van-tabs>
        </div> -->
		<!-- <van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
			<van-grid :column-num="3" :gutter="10" ref="Main">
				<van-grid-item @click="profile(v)" v-for="(v, k) in datalist" :key="k">
					<van-image class="game_item_img" :src="v.img_url" :height="150">
						<template v-slot:loading>
							<van-loading type="spinner" size="20" />
						</template>
						<template v-slot:error><van-image src="/img/error.png" /></template>
					</van-image>
					<span class="rig-name">{{ v.xuanfei_name }}</span>
					<span class="toptxt">cùng một thành phố</span>
				</van-grid-item>
			</van-grid>
		</van-pull-refresh> -->

		<!-- <van-popup v-model="show" position="bottom" closeable round :style="{ minHeight: '60%' }">
			<div style="padding: 20px 0; color: #62519a; font-size: 22px; text-align: center;">tất cả các địa chỉ</div>
			<div class="cityBox">
				<div v-for="(val, key) in addlist" :key="key">
					<div v-for="(v, k) in val" :key="k" @click="addgo(v)">{{ v.name }}</div>
				</div>
			</div>
		</van-popup>
		<div class="bottom" ref="bottom"></div> -->
		<van-tabs title-inactive-color="#442889" title-active-color="#c571cf" animated swipeable @change="TabChange">
			<van-tab v-for="(v, key) in addlist" :key="key"
				:title="$i18n.locale == 'ko' ? (v.name || '').split(',')[0] : (v.name || '').split(',')[1]" :name="v.id"
				:title-style="{
					fontWeight: 'bold',
				}"></van-tab>
		</van-tabs>
		<van-list :loading-text="$t('loadingtext')" :finished-text="$t('finishedtext')">
			<div class="pblBox" v-show="listType === 'clm'">
				<div style="width: calc(50% - 5px);" v-for="(v, i) in datalist" :key="i">
					<releaseItem :rowData="v"></releaseItem>
				</div>
			</div>
			<div v-show="listType === 'row'">
				<horizontalItem v-for="(v, i) in datalist" :key="i" :rowData="v"></horizontalItem>
			</div>
		</van-list>
		<van-empty v-if="datalist && datalist.length == 0" image-size="50px"
			style="width: 100%; padding-top: 90px; color: #000;" image="/img/emptyData.png"
			:description="$t('emptyData')" />
	</div>
</template>

<script>
import releaseItem from '@/common/releaseItem.vue'
import horizontalItem from '@/common/horizontalItem.vue'
export default {
	components: {
		releaseItem,
		horizontalItem
	},
	data() {
		return {
			addlist: [],
			bannerSwiperOption: {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 800,
				autoplay: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows: true
				}
			},
			banners: [{}],
			notice: "",
			isLoading: false,
			datalist: [],
			show: false,
			page: 0,

			videolitem: [],
			listType: 'clm',
		};
	},
	mounted() {
		this.scrollWindow()
	},
	methods: {
		getVideoClass() {
			this.$http({
				method: 'get',
				url: 'video_class'
			}).then(res => {
				this.videolitem = res.data;
			})
		},
		scrollWindow() {
			window.addEventListener('scroll', () => {
				this.getMainBox()
			})
		},
		getMainBox() {

			let Main = this.$refs.Main
			let bottom = this.$refs.bottom //底部的相关DOM
			// console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
			// console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
			// console.log(Main.clientHeight);  //父盒子的高度(加上padding和margin)
			// console.log(window.scrollY);     //浏览器右边的滚动条滚动距离

			if (Math.abs(Main.clientHeight - window.innerHeight - (window.scrollY - Main.offsetTop - bottom
				.clientHeight)) <= 0) {

				this.$http({
					method: 'get',
					url: 'xuanfeilist',
					data: {
						id: this.$route.query.id,
						page: this.page,
					}
				}).then(res => {
					this.datalist.push(res.data);
					this.page++;
				});


			}
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t('refreshSuccessful'));
				this.isLoading = false;
			}, 500);
		},
		addgo(data) {
			// if (!localStorage.getItem('token')) {
			//     this.$router.push({ path: '/Login' });
			// } else {
			//     this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			// }
			this.getxuanfeilist(data.id);
			this.show = false;
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list',
				data: {
					page: 1,
					limit: 100,
				},
			}).then(res => {
				let arr = [];
				res.data.map(v => {
					v.map(v2 => {
						arr.push(v2);
					})
				})
				this.addlist = arr;
				console.log("addlist", this.addlist);

				this.getxuanfeilist(this.addlist[0].id);
			});
		},
		TabChange(v) {
			this.getxuanfeilist(v);
		},
		getBanner(data) {
			data.banners.map(v => {
				v.url = this.$config.baseURL + v.url
			});
			this.banners = data.banners;
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.getBanner(res.data); //获取banner
				this.getNotice(res.data); //获取公告
			})

		},
		getNotice(data) {
			this.notice = data.notice;
		},
		toService() {
			return window.location.href = this.$store.getters.getBaseInfo.kefu;
		},
		profile(v) {
			this.$router.push({
				path: '/profile?id=' + v.id
			});
			//  + '&name=' + v.xuanfei_name + '&adsid=' + this.$route.query.id 
		},
		getxuanfeilist(id) {
			let obj = null;
			let obj1 = null;
			if (id) obj = {
				id: id,
				page: this.page
			};
			if (!id) obj1 = {
				page: this.page
			};
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: obj || obj1
			}).then(res => {
				this.datalist = res.data;
				console.log("this.datalist", this.datalist)

			});
		}
	},
	created() {
		this.getAddress();
		this.getBasicConfig();

		this.getVideoClass();//获取视频类目
	}
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;

.pblBox {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	flex-wrap: wrap;
}

.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}

.newnavbar {
	// background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
	background: linear-gradient(30deg, #7d76ef, #d63f8c);
}

.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}

::v-deep .van-nav-bar__content {
	height: 100px;
}

.van-sidebar {
	width: 180px;
}

.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
	padding-bottom: 50px;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}

::v-deep .van-tabs__line {
	background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}

.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}

::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}

.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}

.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}

.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}

.address {
	width: 90%;
	margin: 0 auto;
	padding: 30px;
	height: calc(100vh - 510px);
	overflow: auto;
	background: #fff;
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
}

.notice-swipe {
	// width: calc(100% - 50px);
	height: 85px;
	font-size: @notice-bar-size;
	border-radius: 50px;
}

::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
	overflow: hidden;

}

::v-deep van-grid-item__content {
	position: relative;
}

.rig-name {
	// width: 100%;
	// height: 60px;
	// line-height: 60px;
	// margin-top: 10px;
	// background-color: #f7f7f7;
	// border-radius: 0 0 15px 15px;
	font-size: 15px;
	// padding-left: 10px;
	position: absolute;
	bottom: 20px;
	left: 10px;
	color: #fff;
	font-size: 26px;
}

.toptxt {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(to right, rgba(239, 172, 54, 0.7), rgba(241, 211, 34, 0.8));
	color: #fff;
	padding: 2px 5px;
	text-align: center;
	font-size: 26px;
}

.titBox {
	display: flex;
	padding: 20px;
	justify-content: space-between;
	align-items: center;

	>div:nth-child(1) {
		color: #280064;
		font-weight: 600;
	}

	>div:nth-child(2) {
		background: #b78afb;
		color: #fff;
		padding: 15px;
		border-radius: 10px;
	}
}

.list-wrapper {
	padding: 0 0 200px 0;
}

.cityBox {

	padding: 20px;

	>div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		>div {
			color: #523e90;
			font-size: 26px;
			border-radius: 30px;
			padding: 15px;
			background: #fff;
			margin: 0 20px 20px 0;
		}

	}
}

::v-deep .van-popup {
	background: #ffffffcc;

	.van-popup__close-icon {
		color: #62519a;
		font-size: 36px;
	}
}

.Main {
	border: 1px solid #ccc;

	.Item {
		margin-top: 5px;
		height: 30px;
		line-height: 30px;
		background: red;

	}
}

.bottom {
	background: green;
	height: 0px;
	margin-bottom: 0px;
}

::v-deep .van-tabs__line {
	bottom: 30px;
	width: 60px;
	height: 5px;
	border-radius: 8px;
	background-color: #c571cf;
}

::v-deep .van-empty__description {
	color: #000;
}
</style>