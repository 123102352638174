tính năng bị vô hiệu hóa<template>
	<div class="container page">
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="right">
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh"
				:pulling-text="$t('pullingtext')" :loosing-text="$t('loosingtext')" :loading-text="$t('loadingtext')">
				<van-grid :column-num="2" :gutter="10">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<van-image class="game_item_img" :src="v.img_url">
							<template v-slot:loading>
								<van-loading type="spinner" size="20" />
							</template>
							<template v-slot:error><van-image src="/img/error.png" /></template>
						</van-image>
						<span class="rig-name">{{ $i18n.locale == 'ko' ? (v.xuanfei_name || '').split('|')[0] :
							(v.xuanfei_name
								|| '').split('|')[1] }}</span>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '北京',
			isLoading: false,
			page: 0,
			datalist: [

			]
		};
	},
	mounted() {
		this.scrollWindow()
	},
	methods: {
		scrollWindow() {
			window.addEventListener('scroll', () => {
				this.getMainBox()
			})
		},
		getMainBox() {
			let Main = this.$refs.Main
			let bottom = this.$refs.bottom //底部的相关DOM
			// console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
			// console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
			// console.log(Main.clientHeight);  //父盒子的高度(加上padding和margin)
			// console.log(window.scrollY);     //浏览器右边的滚动条滚动距离
			if (Math.abs(Main.clientHeight - window.innerHeight - (window.scrollY - Main.offsetTop - bottom
				.clientHeight)) <= 10) {
				console.log('我滑动到底部了');
				alert('12323132');
				this.$http({
					method: 'get',
					url: 'xuanfeilist',
					data: {
						id: this.$route.query.id,
						page: this.page,
					}
				}).then(res => {
					this.datalist = res.data;
				});


			}
		},
		back() {
			this.$router.push({
				path: 'Choose'
			});
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t('refreshSuccessful'));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({
				path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id
			});
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: {
					id: this.$route.query.id,
					page: this.page,
				}
			}).then(res => {
				this.datalist = res.data;
				this.page++;
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	}
};
</script>

<style scoped>
.right {
	margin-top: 10px;
}

::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
}

::v-deep .van-image__img {
	border-radius: 10px;
	padding: 15px;
}

.rig-name {
	width: 100%;
	height: 60px;
	line-height: 60px;
	margin-top: 10px;
	background-color: #f7f7f7;
	border-radius: 0 0 15px 15px;
	font-size: 15px;
	padding-left: 10px;
}
</style>